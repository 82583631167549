import React, { useState } from 'react';
import './style.css';

export const DeleteAccountRequest = () => {
  
  const [email, setEmail] = useState('');

  const [reason, setReason] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      email: email,
      reason: reason
    };

    fetch('/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(function(response) {
      // handle the response
    });
  };

  return (
    <div class="wrapper">
    <h1>App name</h1>
    <h2> Delete Your Account</h2>
    <form onSubmit={handleSubmit} id="form" class="validate">

      <div class="form-field">
        <label for="email-input">Email</label>
        <input value={email} onChange={e => setEmail(e.target.value)} type="email" name="email-input" id="email-input" placeholder="example@domain.com" required />
      </div>

      <div class="form-field text-area">
        <label for="reason-input">Reason</label>
        <textarea value={reason} onChange={e => setReason(e.target.value)} name="reason-input" id="reason-input" rows="4" cols="50" placeholder="Enter your reason here" required></textarea>
      </div>

      <div class="form-field">
        <label for=""></label>
        <input type="submit" value="Submit" />
      </div>
      
    </form>
  </div>
  )
}

export default DeleteAccountRequest;